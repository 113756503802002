/*eslint no-unused-vars: "off"*/

/**
 * @see: https://github.com/jaredpalmer/formik/tree/master/examples
 */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Slider, Radio, RadioGroup, Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel, FormHelperText } from '@material-ui/core';

// // Material UI Datepicker START
// // @see: https://material-ui-pickers.dev/getting-started/installation
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// // pick a date util library
// import MomentUtils from '@date-io/moment';
// // Material UI Datepicker STOP

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
import { DisplayFormikState } from "./../formikHelper"



const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});



const FormStateless = (props) => {
  const { classes, intl } = props;
  const t = intl.formatMessage;

  // Declare a new state variable, which we'll call "formStatus"
  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  // Declare a new state variable, which we'll call "formStatus"
  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  let initialValues = {
    e_mail: ``,
    jak_czesto_wspolpracuja_panstwo_z_firma_heuresis: ``,
  }

  // let validationSchema = Yup.object().shape({
  //   e_mail: Yup.string().email().required(),
  // })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = `heuresis_outsourcing_diagnoza_fi`;
  // oFormHelper.formEndpoint = '';
  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    // Form schema does not contain markup elements!
    oFormHelper.getFormSchema(initialValues.webform_id);
  }, []);



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <Formik
          // schema={state.formSchema}
          // initialValues={initialValues}
          initialValues={oFormHelper.initialValuesPrepare(initialValues)}
          onSubmit={oFormHelper.onSubmit}
          // validationSchema={validationSchema}
          validationSchema={Yup.object().shape(oFormHelper.validationSchemaPrepare(Yup))}
        >

          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;

            // console.log(status)

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            return (
              <Form 
                  onSubmit={handleSubmit} 
                  className={initialValues.webform_id}
                >

                <div className="row">
                  <div className="col">
                    <TextField
                      id={`${initialValues.webform_id}_e_mail`}
                      label={ oFormHelper.getFieldTitle(`e_mail`) }
                      name="e_mail"
                      error={errors.e_mail && touched.e_mail}
                      className={classes.textField}
                      value={values.e_mail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.e_mail && touched.e_mail) && errors.e_mail}
                      margin="normal"
                    />
                  </div>
                </div>

                {oFormHelper.state.formSchema && oFormHelper.state.formSchema.schema && Object.keys(oFormHelper.state.formSchema.schema.properties).map(fieldId => {

                  if (`radio` !== oFormHelper.state.formSchema.ui[fieldId][`ui:widget`]) { // skip non option value
                    return null;
                  }

                  const field = oFormHelper.state.formSchema.schema.properties[fieldId];
                  // console.log(fieldId)
                  // console.log(field)

                  return (
                    <React.Fragment>
                      {/* {`1_czy_organizacja_posiada_strategie_krotkoterminowa_kolejny_rok_` === fieldId && 
                        <strong className="d-block pt-3 pb-2">{ t({ id: `soft4net_webform___heuresis_outsourcing_diagnoza_fi___section_3` }) }</strong>} */}
                      {`a_weryfikacji_` === fieldId && 
                        <strong className="d-block pt-3 pb-2">{ t({ id: `soft4net_webform___heuresis_outsourcing_diagnoza_fi___section_1` }) }</strong>}
                      {`a_szybkie_odchodzenie_nowych_pracownikow_` === fieldId && 
                        <strong className="d-block pt-3 pb-2">{ t({ id: `soft4net_webform___heuresis_outsourcing_diagnoza_fi___section_2` }) }</strong>}

                      <FormControl 
                        component="fieldset"
                        // className={classes.formControl}
                      >
                        <FormLabel 
                          component="legend"
                          error={errors[fieldId] && touched[fieldId]}
                          helperText={(errors[fieldId] && touched[fieldId]) && errors[fieldId]}
                        >{field.title}</FormLabel>
                        <RadioGroup 
                          aria-label={fieldId} 
                          name={fieldId} 
                          // value={value} 
                          // value={values[fieldId]} 
                          // onChange={(event) => {
                          //   handleChange(event)
                          // }} 
                          onChange={handleChange} 
                          row
                        >

                            {Array.isArray(field.anyOf) && field.anyOf.map(option => {
                              const radio = option.enum[0]; // const radio = option.title;
                              // console.log(option)

                              return <FormControlLabel
                                value={radio}
                                control={
                                  <Radio 
                                    color="primary"
                                    checked={radio === values[fieldId]} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                  />
                                }
                                label={option.title}
                                // labelPlacement="end"
                              />

                            })}

                        </RadioGroup>
                        {/* <FormHelperText>labelPlacement start</FormHelperText> */}
                      </FormControl>
                    </React.Fragment>
                  )
                })}



                {/* Form status messages */}
                <div className="row form-status">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert">{/* {formStatus.message} */}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <DialogActions>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                      {isSubmitting ? 
                        <CircularProgress /> : 
                        <Button 
                          type="submit" 
                          variant="contained" 
                          color="primary"
                          className={formStatus && formStatus.success ? 'green' : null}
                          disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                      }

                    </DialogActions>
                  </div>
                </div>



                {/* <DisplayFormikState {...props} /> */}



              </Form>
            )
          }}
        </Formik>
      }
    </React.Fragment>
  )
}

export default withStyles(styles)(injectIntl(FormStateless))